import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar'; 
import { useNavigate } from 'react-router-dom';

const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

const IzinSayfasi = () => {
    const [personeller, setPersoneller] = useState([]);
    const [izinler, setIzinler] = useState([]);
    const [selectedPersonel, setSelectedPersonel] = useState('');
    const [izinTur, setIzinTur] = useState('Yıllık İzin');
    const [basTar, setBasTar] = useState('');
    const [bitTar, setBitTar] = useState('');
    const [basSaat, setBasSaat] = useState('');
    const [bitSaat, setBitSaat] = useState('');
    const [aciklama, setAciklama] = useState('');
    const role = localStorage.getItem('role');
    const username = localStorage.getItem('username');

    const navigate = useNavigate();

    const currentYear = new Date().getFullYear();
    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    useEffect(() => {
        fetch(`https://api.idekod.com.tr/api/personel?role=${role}&username=${username}`)
            .then(response => response.json())
            .then(data => {
                setPersoneller(data);
                if (role !== '1') {
                    setSelectedPersonel(data[0]?.TELNO);
                }
            });

        fetchIzinler();
    }, [role, username]);

    const fetchIzinler = () => {
    
        fetch('https://api.idekod.com.tr/api/izinler?role=' + role + '&username=' + username )
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data)) {
                    setIzinler(data);
                } else {
                    console.error('Beklenen dizi formatında değil:', data);
                    setIzinler([]); // Boş bir dizi ile güncelle
                }
            })
            .catch(error => console.error('İzinler alınırken hata oluştu:', error));
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            TELNO: selectedPersonel,
            IZINTUR: izinTur,
            BASTAR: basTar,
            BITTAR: bitTar,
            BASSAAT: izinTur === 'Saatlik İzin' ? basSaat : null,
            BITSAAT: izinTur === 'Saatlik İzin' ? bitSaat : null,
            ACIKLAMA: aciklama
        };
        console.log(payload);
        fetch('https://api.idekod.com.tr/api/izin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                alert(data.message);
                fetchIzinler(); // İzinler güncellendiğinde tabloyu yenile
            });
    };

    const handleOnayla = (telno) => {
        fetch('https://api.idekod.com.tr/api/izin/onayla', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ telno })
        })
            .then(response => response.json())
            .then(data => {
                alert(data.message);
                fetchIzinler(); // İzinler güncellendiğinde tabloyu yenile
            })
            .catch(error => console.error('Fetch error:', error));
    };
    

    const handleIptal = (telno,PK) => {
        console.log("Telno:", telno, "PK:", PK);
        const IPTAL_ACK = prompt('İptal nedenini girin:'); // İptal nedeni için basit bir prompt
        if (IPTAL_ACK) {
            fetch('https://api.idekod.com.tr/api/izin/iptal', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ telno,PK, IPTAL_ACK })
            })
                .then(response => response.json())
                .then(data => {
                    alert(data.message);
                    fetchIzinler(); // İzinler güncellendiğinde tabloyu yenile
                })
                .catch(error => console.error('Fetch error:', error));
        }
    };

    
    

    return (
      <div className="p-6 space-y-8">
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
    
       <div className="bg-gray-600 p-4 text-white">
      
       <h1 className="text-xl font-semibold text-center">İzin Talep Formu</h1>
  

  
  
    </div>
       
        <form onSubmit={handleSubmit} className="min-w-full table-auto mb-2 py-2 px-4"> {/* Form stili */}
          
          {role === '1' && (
            <select value={selectedPersonel} onChange={(e) => setSelectedPersonel(e.target.value)}  className="mb-4 border rounded p-2 w-full">
              {personeller.map(personel => (
                <option key={personel.TELNO} value={personel.TELNO}>{personel.RNAME}</option>
              ))}
            </select>
          )}
          <select value={izinTur} onChange={(e) => setIzinTur(e.target.value)}  className="mb-4 border rounded p-2 w-full">
            <option value="Yıllık İzin">Yıllık İzin</option>
            <option value="Günlük İzin">Günlük İzin</option>
            <option value="Saatlik İzin">Saatlik İzin</option>
          </select>
          <input type="date" value={basTar} onChange={(e) => setBasTar(e.target.value)} required className="mb-4 border rounded p-2 w-full" />
          <input type="date" value={bitTar} onChange={(e) => setBitTar(e.target.value)} required className="mb-4 border rounded p-2 w-full" />
          {izinTur === 'Saatlik İzin' && (
            <>
              <input type="time" value={basSaat} onChange={(e) => setBasSaat(e.target.value)} required className="mb-4 border rounded p-2 w-full" />
              <input type="time" value={bitSaat} onChange={(e) => setBitSaat(e.target.value)} required className="mb-4 border rounded p-2 w-full" />
            </>
          )}
          <textarea value={aciklama} onChange={(e) => setAciklama(e.target.value)} placeholder="Açıklama" className="mb-4 border rounded p-2 w-full" />

          <div className="flex justify-center mt-4"> {/* Buton hizalaması için flex ayarı */}
            <button type="submit" className="w-full bg-customControl text-white font-semibold py-2 rounded-lg hover:bg-customColor2 transition duration-200">Kaydet ve Onaya Gönder</button>
          </div>
        </form>



        </div>
      </div>
      
    );
};

export default IzinSayfasi;
