import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';


const Personel = () => {
    const [personelList, setPersonelList] = useState([]);
    const [selectedPersonel, setSelectedPersonel] = useState(null);
    const [updatedInfo, setUpdatedInfo] = useState({
        RNAME: '',
        USERN: '',
        PASSWORD: '',
        STATE: '',
        COMPANY: '',
        telno: '',
        eposta: '',
        role: ''
    });
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    useEffect(() => {
        fetch('https://api.idekod.com.tr/api/personeledit')
            .then(response => response.json())
            .then(data => setPersonelList(data))
            .catch(error => console.error('Personel bilgileri alınırken hata oluştu:', error));
    }, []);

    const handleSelectChange = (e) => {
        const selected = personelList.find(p => p.PK.toString() === e.target.value);
        setSelectedPersonel(selected);
        if (selected) {
            setUpdatedInfo({
                RNAME: selected.RNAME,
                USERN: selected.USERN,
                PASSWORD: selected.PASSWORD,
                STATE: selected.STATE,
                COMPANY: selected.COMPANY,
                telno: selected.telno,
                eposta: selected.eposta,
                role: selected.role
            });
        }
    };

    const handleUpdate = () => {
        if (selectedPersonel) {
            fetch(`https://api.idekod.com.tr/api/personel/${selectedPersonel.telno}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedInfo),
            })
                .then(response => response.json())
                .then(data => {
                    console.log('Update Response:', data);
                    // Güncelleme sonrası kullanıcı listesini tekrar yükleyin
                    fetch('https://api.idekod.com.tr/api/personeledit')
                        .then(response => response.json())
                        .then(data => setPersonelList(data))
                        .catch(error => console.error('Personel bilgileri alınırken hata oluştu:', error));
                })
                .catch(error => console.error('Personel bilgileri güncellenirken hata oluştu:', error));
        }
    };

    return (
        <div className="p-6 space-y-8">
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
    
    <div className="bg-gray-600 p-4 text-white">
    <h1 className="text-xl font-semibold text-center">Kullanıcı Düzenle</h1>
    </div>
    <div className="bg-gray-600 p-4 text-black ">
    <label className="block text-sm  text-white font-medium text-gray-700 mb-2">
                             Düzenlenecek kullanıcı Seçiniz:
                    </label>
                    <select className="input-date border text-black rounded-md px-3 py-2 mb-4 w-full max-w-xs" onChange={handleSelectChange} value={selectedPersonel ? selectedPersonel.PK : ""}>
                        <option value="" disabled>Kullanıcı Seçin</option>
                        {personelList.map(personel => (
                            <option key={personel.PK} value={personel.PK.toString()}>
                                {personel.RNAME}
                            </option>
                        ))}
                    </select>
    </div>
    
    

                {selectedPersonel && (
                    <div className="min-w-full table-auto py-2 px-4">
                       

                        <label className="py-2 px-4 border-b text-left">
                            Adı-Soyadı:
                            <input
                                type="text"
                                value={updatedInfo.RNAME}
                                onChange={(e) => setUpdatedInfo({ ...updatedInfo, RNAME: e.target.value })}
                                 className="focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </label>
                        <label className="py-2 px-4 border-b text-left">
                            Kullanıcı Adı:
                            <input
                                type="text"
                                value={updatedInfo.USERN}
                                onChange={(e) => setUpdatedInfo({ ...updatedInfo, USERN: e.target.value })}
                                 className="focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </label>
                        <label className="py-2 px-4 border-b text-left">
                            Şifre:
                            <input
                                type="password"
                                value={updatedInfo.PASSWORD}
                                onChange={(e) => setUpdatedInfo({ ...updatedInfo, PASSWORD: e.target.value })}
                                 className="focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </label>
                        <label className="py-2 px-4 border-b text-left">
                            Durum: " 0 - Pasif / 1 - Aktif / 3 - Bekleyen "
                            <input
                                type="text"
                                value={updatedInfo.STATE}
                                onChange={(e) => setUpdatedInfo({ ...updatedInfo, STATE: e.target.value })}
                                 className="focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </label>
                        <label className="py-2 px-4 border-b text-left">
                            Şirket:
                            <input
                                type="text"
                                value={updatedInfo.COMPANY}
                                onChange={(e) => setUpdatedInfo({ ...updatedInfo, COMPANY: e.target.value })}
                                 className="focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </label>
                        <label className="py-2 px-4 border-b text-left">
                            Telefon:
                            <input
                                type="text"
                                value={updatedInfo.telno}
                                onChange={(e) => setUpdatedInfo({ ...updatedInfo, telno: e.target.value })}
                                 className="focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </label>
                        <label className="py-2 px-4 border-b text-left">
                            E-posta:
                            <input
                                type="email"
                                value={updatedInfo.eposta}
                                onChange={(e) => setUpdatedInfo({ ...updatedInfo, eposta: e.target.value })}
                                 className="focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </label>
                        <label className="py-2 px-4 border-b text-left">
                            Rol:
                            <input
                                type="text"
                                value={updatedInfo.role}
                                onChange={(e) => setUpdatedInfo({ ...updatedInfo, role: e.target.value })}
                                 className="focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </label>
                        <button onClick={handleUpdate} className="w-full bg-customControl text-white font-semibold py-2 rounded-lg hover:bg-customColor2 transition duration-200">Güncelle</button>
                    </div>
                )}

        </div>
      </div>
    );
};

export default Personel;
