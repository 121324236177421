import React, { useEffect, useState } from "react";

const TarihSec = () => {
  const [settings, setSettings] = useState({
    isHolidayChecked: false,
    saturdayType: "none",
    sundayType: "none",
  });
  const [holidays, setHolidays] = useState([]);

  // API'den ayarları al
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await fetch("https://api.idekod.com.tr/api/settings");
        const data = await response.json();
        setSettings({
          isHolidayChecked: data?.isHolidayChecked || false,
          saturdayType: data?.saturdayType || "none",
          sundayType: data?.sundayType || "none",
        });
        setHolidays(data.holidays || []); // Özel günler
      } catch (error) {
        console.error("Ayarları alırken hata:", error);
      }
    };
    fetchSettings();
  }, []);
  
  

  // Ayarları kaydet
  const handleSaveSettings = async () => {
    console.log(settings.isHolidayChecked); // Ayarın doğru güncellenip güncellenmediğini kontrol edin

    try {
      const response = await fetch("https://api.idekod.com.tr/api/settings", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          isHolidayChecked: settings.isHolidayChecked,
          saturdayType: settings.saturdayType,
          sundayType: settings.sundayType,
          holidays,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Hata: ${response.statusText}`);
      }
  
      alert("Ayarlar kaydedildi!");
    } catch (error) {
      console.error("Ayarları kaydederken hata:", error);
    }
  };
  
  
  // Yıl takvimi oluştur
  const generateYearCalendar = (year) => {
    const daysInWeek = ["Pzt", "Sal", "Çar", "Per", "Cum", "Cmt", "Paz"];
    const months = [];
    for (let month = 0; month < 12; month++) {
      const days = [];
      const totalDays = new Date(year, month + 1, 0).getDate();
      const firstDay = (new Date(year, month, 1).getDay() + 6) % 7; // Pazar sağda olacak şekilde ayarla

      // Boş günler ekle
      for (let i = 0; i < firstDay; i++) {
        days.push(null);
      }

      // Günleri ekle
      for (let date = 1; date <= totalDays; date++) {
        days.push(new Date(year, month, date));
      }

      months.push({ month, days, daysInWeek });
    }
    return months;
  };

  const currentYear = new Date().getFullYear();
  const calendar = generateYearCalendar(currentYear);

  // Gün rengi belirleme
  const getDayColor = (date) => {
    if (!date) return "bg-gray-200"; // Boş günler

    const day = date.getDay(); // 0: Pazar, 6: Cumartesi
    const isHoliday = holidays.some(
      (holiday) => new Date(holiday).toDateString() === date.toDateString()
    );

    if (isHoliday) return "bg-red-500 text-white"; // Resmi tatiller

    if (day === 6) {
      return settings.saturdayType === "tam"
        ? "bg-red-500 text-white"
        : settings.saturdayType === "yarim"
        ? "bg-orange-500 text-white"
        : "bg-gray-300";
    }
    if (day === 0) {
      return settings.sundayType === "tam"
        ? "bg-red-500 text-white"
        : settings.sundayType === "yarim"
        ? "bg-orange-500 text-white"
        : "bg-gray-300";
    }
    return "bg-white";
  };

  return (
    <div className="p-6 space-y-8">
      {/* Ayar Alanı */}
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        
        <div className="bg-gray-600 p-4 text-white ">
        <h1 className="text-xl font-semibold text-center">Ayarlar</h1>
        </div>
        
        <table className="min-w-full table-auto border-collapse">
  <thead>
    <tr className="bg-gray-200 text-gray-700">
      <th className="px-4 py-2 text-left">Resmi Tatilleri İşaretle</th>
      <th className="px-4 py-2 text-left">Cumartesi:</th>
      <th className="px-4 py-2 text-left">Pazar:</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <input
          type="checkbox"
          checked={settings.isHolidayChecked}
          onChange={(e) =>
            setSettings({ ...settings, isHolidayChecked: e.target.checked })
          }
          className="h-4 w-4"
        />
      </td>
      <td>
        <div className="flex items-center">
          <input
            type="radio"
            id="saturday_none"
            value="none"
            checked={settings.saturdayType === "none"}
            onChange={(e) =>
              setSettings({ ...settings, saturdayType: e.target.value })
            }
            className="hidden peer"
          />
          <label
            htmlFor="saturday_none"
            className="block w-full cursor-pointer rounded border border-gray-300 p-2 text-center peer-checked:bg-green-500 peer-checked:text-white"
          >
            Hiçbir Ayar Yok
          </label>
        </div>
      </td>
      <td>
        <div className="flex items-center">
          <input
            type="radio"
            id="sunday_none"
            value="none"
            checked={settings.sundayType === "none"}
            onChange={(e) =>
              setSettings({ ...settings, sundayType: e.target.value })
            }
            className="hidden peer"
          />
          <label
            htmlFor="sunday_none"
            className="block w-full cursor-pointer rounded border border-gray-300 p-2 text-center peer-checked:bg-green-500 peer-checked:text-white"
          >
            Hiçbir Ayar Yok
          </label>
        </div>
      </td>
    </tr>
    <tr>
      <td></td>
      <td>
        <div className="flex items-center">
          <input
            type="radio"
            id="saturday_half"
            value="yarim"
            checked={settings.saturdayType === "yarim"}
            onChange={(e) =>
              setSettings({ ...settings, saturdayType: e.target.value })
            }
            className="hidden peer"
          />
          <label
            htmlFor="saturday_half"
            className="block w-full cursor-pointer rounded border border-gray-300 p-2 text-center peer-checked:bg-green-500 peer-checked:text-white"
          >
            Yarım Gün
          </label>
        </div>
      </td>
      <td>
        <div className="flex items-center">
          <input
            type="radio"
            id="sunday_half"
            value="yarim"
            checked={settings.sundayType === "yarim"}
            onChange={(e) =>
              setSettings({ ...settings, sundayType: e.target.value })
            }
            className="hidden peer"
          />
          <label
            htmlFor="sunday_half"
            className="block w-full cursor-pointer rounded border border-gray-300 p-2 text-center peer-checked:bg-green-500 peer-checked:text-white"
          >
            Yarım Gün
          </label>
        </div>
      </td>
    </tr>
    <tr>
      <td></td>
      <td>
        <div className="flex items-center">
          <input
            type="radio"
            id="saturday_full"
            value="tam"
            checked={settings.saturdayType === "tam"}
            onChange={(e) =>
              setSettings({ ...settings, saturdayType: e.target.value })
            }
            className="hidden peer"
          />
          <label
            htmlFor="saturday_full"
            className="block w-full cursor-pointer rounded border border-gray-300 p-2 text-center peer-checked:bg-green-500 peer-checked:text-white"
          >
            Tam Gün
          </label>
        </div>
      </td>
      <td>
        <div className="flex items-center">
          <input
            type="radio"
            id="sunday_full"
            value="tam"
            checked={settings.sundayType === "tam"}
            onChange={(e) =>
              setSettings({ ...settings, sundayType: e.target.value })
            }
            className="hidden peer"
          />
          <label
            htmlFor="sunday_full"
            className="block w-full cursor-pointer rounded border border-gray-300 p-2 text-center peer-checked:bg-green-500 peer-checked:text-white"
          >
            Tam Gün
          </label>
        </div>
      </td>
    </tr>
    <tr>
      <td colSpan="3" className="text-center">
        <button
          onClick={handleSaveSettings}
          className="bg-blue-500 text-white px-4 py-2 rounded shadow w-full"
        >
          Kaydet
        </button>
      </td>
    </tr>
  </tbody>
</table>


  
        
    

      {/* Takvim */}
      <div className="grid grid-cols-3 gap-4">
        {calendar.map((month) => (
          <div key={month.month} className="bg-gray-100 p-2 rounded shadow">
            <h2 className="text-xl font-semibold mb-2">
              {new Date(currentYear, month.month).toLocaleString("tr-TR", {
                month: "long",
              })}
            </h2>
            <div className="grid grid-cols-7 gap-1">
              {month.daysInWeek.map((day) => (
                <div key={day} className="text-center font-bold">
                  {day}
                </div>
              ))}
              {month.days.map((date, index) => (
                <div
                  key={index}
                  className={`text-center p-2 rounded ${getDayColor(date)}`}
                >
                  {date ? date.getDate() : ""}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default TarihSec;
